import React from 'react';
import Container from '../../components/Container';


const Chatbot = () => {
    return (
        <Container extraclas={'px-6  md:px-0 mb-8'}>
        <div className='grid  text-justify md:grid-cols-2 mt-10'>
            <div className='md:w-[400px] sm:w-[200px] flex justify-center items-center' ><img src="img/products/chatbot.jpg" alt="" /></div>
            <div className='flex px-2 flex-col gap-3'>
<p className='text-lg text-[#000]'>digisky- WhatsApp marketing software</p>
<hr />
<p className='text-sm text-[#777]'>In today's digital landscape, marketing and communication are becoming highly interconnected. If you are a business owner, you need to consider a reliable tool to help you enact digital communication and marketing strategies efficiently. WhatsApp Business, the leading business communication platform, is fast becoming a favorite among businesses as it enables them to reach & engage with a large group of customers effortlessly. The platform also offers specialized tools that help business owners market their products & services. Through WhatsApp Business automation, you can increase your visibility, gain unique insights & assist your customers with a minimal workforce.</p>
<p className='text-sm text-[]'>WhatsApp marketing is becoming increasingly important, even essential for the modern business owner. WhatsApp’s enormous user base, highly targeted & engaging user interface, and low overhead make it perfect for marketing automation. With a few tweaks and some strategic planning, you can automate your marketing and sales processes either by creating local/global groups or by sending bulk broadcast messages that can convert leads and increase sales.</p>
</div>

        </div>
        <div className='grid  text-justify md:grid-cols-2 mt-10'>
        <div className='flex px-2 flex-col gap-3'>
<p className='text-lg text-[#000]'>digisky- WhatsApp marketing software </p>
<hr />
<p className='text-sm text-[#777]'>Until recently, WhatsApp Business didn’t allow businesses to directly promote their products and services. The only way to create a superior marketing strategy through WhatsApp is by using a WhatsApp Business API. However, the API is a programming interface that doesn’t have a front end. Therefore, businesses must partner with WhatsApp Business Service Providers who can use WhatsApp marketing software to help them create customized marketing solutions for their product offerings.</p>
<p className='text-sm text-[]'>WhatsApp marketing software helps your business conveniently send bulk product notifications & promotions to a large group of customers instantly. Furthermore, some of them provide WhatsApp chat & chatbot automation capabilities for better management and optimization of your business’s operations. By partnering with the right marketing software provider, your business will have access to a number of features that help increase your brand visibility & engagement.</p>
</div>
            <div className='md:w-[400px] md:ml-20 sm:w-[200px] flex justify-center items-center' ><img src="img/products/chatbot.jpg" alt="" /></div>
        </div>

    </Container>
    );
}

export default Chatbot;
