import React from 'react';
import Contact from './pages/Contact';
import { createBrowserRouter,RouterProvider } from 'react-router-dom';
import Home from './pages/Home';
import Services from './pages/Service';
import About from './pages/About';
import Main from './pages/Main';
import OurClints from './pages/OurClints';
import Chatbot from './pages/service/Chatbot';
import AppDevp from './pages/service/AppDevp';
import SoftwareDevp from './pages/service/SoftwareDevp';
import StudioSetup from './pages/service/StudioSetup';
import TextMsg from './pages/service/TextMsg'
import VistingCard from './pages/service/VistingCard'
import VoiceCall from './pages/service/VoiceCall'
import WebsiteDevp from './pages/service/WebsiteDevp'
import TermCandition from './components/Term_candition';
import PrivacyPolicy from './components/Privacy_policy';

const routes = createBrowserRouter([
  {
    path: '/',
    element: <Main />,
    children: [
      {
        path: "",
        element: <Home />
      },
      {
        path: '/about',
        element: <About/>
      },
      {
        path: '/contact',
        element: <Contact/>
      },
      {
        path: '/OurClient',
        element: <OurClints/>
      },
      {
        path: '/services',
        element: <Services/>
      },{
        path: '/appdev',
        element: <AppDevp/>
      },{
        path: '/whatsapp_message',
        element: <Chatbot/>
      },{
        path: '/software_development',
        element: <SoftwareDevp/>
      },{
        path: '/studio_setup',
        element: <StudioSetup/>
      },{
        path: '/text_message',
        element: <TextMsg/>
      },{
        path: '/visting_card',
        element: <VistingCard/>
      },
      {
        path: '/voice_call',
        element: <VoiceCall/>
      }
      ,
      {
        path: '/website_develpment',
        element: <WebsiteDevp/>
      } ,
      {
        path: '/term_condition',
        element: <TermCandition/>
      },
      {
        path: '/privacy_policy',
        element: <PrivacyPolicy/>
      }
    ]
  },

]
)


const App = () => {
  return (
      <RouterProvider router={routes} />
  );
}

export default App;
