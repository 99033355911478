import React,{useContext} from 'react';
import { BsFacebook, BsWhatsapp, BsInstagram, BsYoutube, BsTelephonePlusFill, BsTwitter, BsLinkedin } from "react-icons/bs"
import { AiOutlineArrowUp } from "react-icons/ai"
import Container from './Container';
import { Link } from 'react-router-dom';
import { MainContext } from '../context/Context';


const Footers = () => {
  const {scrollToTop} = useContext(MainContext);

  const socialMediaLinks = [
    {
        name: 'Facebook',
        icon: BsFacebook,
        url: 'https://www.facebook.com/DigiSky-110083901481716/',
    },
    {
        name: 'WhatsApp',
        icon: BsWhatsapp,
        url: 'https://api.whatsapp.com/send/?phone=9929245508&text=Hello%2C+I+am+interested+in your services',
    },
    {
        name: 'Instagram',
        icon: BsInstagram,
        url: 'https://www.instagram.com/digiskyofficial/?igshid=MzRlODBiNWFlZA%3D%3D',
    },
    {
        name: 'YouTube',
        icon: BsYoutube,
        url: ' https://www.youtube.com/@Digisky23 ',
    },
    {
        name: 'LinkedIn',
        icon: BsLinkedin,
        url: 'https://www.linkedin.com/in/digi-sky-090ba0270?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app ',
    }
];
  const handleSocialClick = (url) => {
    window.location.href = url
  }
 
  const MenuItems = [
    {
        name: "About",
        link: "/about"
    },
   
    {
        name: "Contact",
        link: "/contact"
    },{
      name: "Privacy & Policy",
      link: "/privacy_policy"
  },{
    name: "Term & condition",
    link: "/term_condition"
}
]
  return (
    <Container fluid={true}>
      <div style={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}}>
        <footer className="relative bg-[white] shadow-lg pt-8 pb-2">
          <div className="container mx-auto px-4">
            <div >
              <div className='grid gap-5 md:grid-cols-3'>
                <div >
                  <h4 className="text-3xl fonat-semibold text-blueGray-700">Let's keep in touch!</h4>
                  <h5 className="text-lg mt-0 mb-2 text-blueGray-600">
                    Find Digitalsky us on any of these platforms, we respond 1-2 business days.
                  </h5>
                  <div className="mt-6  flex lg:mb-0 mb-6 ">
                    {socialMediaLinks.map((socialMedia, index) => (
                      <socialMedia.icon className="shadow-lg transition duration-300 ease-in-out hover:scale-110 font-normal text-[30px] items-center justify-center cursor-pointer align-center rounded-full outline-none focus:outline-none mr-2"
                        key={index}
                        onClick={() => handleSocialClick(socialMedia.url)}
                      />
                    ))}
                  </div>
                </div>
                <div className=' md:ml-10 '>
                  <span className="block uppercase text-md text-[#000000] font-semibold mb-2">Useful Links</span>
                  <ul className="list-unstyled text-[12px]">
                    
                    {
                      MenuItems.map(
                        (menu,index)=>{
                        return  <li key={index}>
                      <Link onClick={scrollToTop}  to={menu.link} className="text-blueGray-600 hover:text-blueGray-800 block pb-2 text-sm">{menu.name}</Link>
                    </li>
                        }
                      )
                    }
                  </ul>
                </div>
                <div>
                  <span className="block uppercase text-[16px] text-[#000000] font-semibold mb-2">Registation Office</span>
                  <ul className="list-unstyled text-sm ">
                    <li> 23, 1st Floor,  Gajananad Market, Sheopur Road, Pratap Nagar, Jaipur 
                    (Raj.) 302033</li>
                    <li className='mt-2'>Digiskymanager@gmail.com</li>
                    <li className='mt-2'>+91-9929245508</li>
                  </ul>
                </div>
              </div>
              <div className="container flex justify-end">
                    <button onClick={scrollToTop} className="scroll-to-top-button   inline-block ">
                        <AiOutlineArrowUp className="w-[30px]  rounded-full border-red-600 border  text-center animate-bounce  h-[30px] text-[blue]" />
                    </button>
                </div>
            </div>
            <hr className="my-2 border-blueGray-300" />
            <div className="flex flex-wrap items-center md:justify-between justify-center">
              <div style={{borderTop:'2px solid black'}} className="w-full md:w-full px-2 mx-auto text-center">
                <div className="text-sm mt-2   text-blueGray-500 font-semibold ">
                All Right Reserved. Copyright © DIGISKY
                </div>
              </div>
            </div>
          </div>
        
        </footer>
      </div>
    </Container>
  );
}

export default Footers;
