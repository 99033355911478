import React from "react";
import Container from "../../components/Container";

const SoftwareDevp = () => {
  return (
    <Container extraclas={"px-6  md:px-0 mb-8"}>
      <div className="grid  text-justify md:grid-cols-2 mt-10">
        <div className="md:w-[400px] sm:w-[200px] flex justify-center items-center">
          <img src="img/products/cursor4.jpg" alt="" />
        </div>
        <div className="flex px-2 flex-col gap-3">
          <p className="text-lg text-[#000]">digisky-software develpment</p>
          <hr />
          <p className="text-sm text-[#777]">
            Are you ready to revolutionize your workflow and supercharge your
            productivity? Look no further! Our cutting-edge software is designed
            to streamline your tasks, simplify your processes, and help you
            achieve your goals with ease. With a user-friendly interface and a
            powerful set of features, our software is your ultimate solution.
            Whether you're a small business owner, a freelancer, or part of a
            large corporation, our software is tailored to meet your unique
            needs. Don't miss out on the opportunity to take your work to the
            next level. Try our software today and experience the difference for
            yourself. Your success is just a click away! Feel free to adapt this
            paragraph to suit your specific software and target audience.
          </p>
          <p className="text-sm text-[]">
            Are you ready to revolutionize your workflow and supercharge your
            productivity? Look no further! Our cutting-edge software is designed
            to streamline your tasks, simplify your processes, and help you
            achieve your goals with ease. With a user-friendly interface and a
            powerful set of features, our software is your ultimate solution.
            Whether you're a small business owner, a freelancer, or part of a
            large corporation, our software is tailored to meet your unique
            needs. Don't miss out on the opportunity to take your work to the
            next level. Try our software today and experience the difference for
            yourself. Your success is just a click away! Feel free to adapt this
            paragraph to suit your specific software and target audience.
          </p>
        </div>
      </div>
      <div className="grid  text-justify md:grid-cols-2 mt-10">
        <div className="flex px-2 flex-col gap-3">
          <p className="text-lg text-[#000]">digisky-software develpment</p>
          <hr />
          <p className="text-sm text-[#777]">
            Are you ready to revolutionize your workflow and supercharge your
            productivity? Look no further! Our cutting-edge software is designed
            to streamline your tasks, simplify your processes, and help you
            achieve your goals with ease. With a user-friendly interface and a
            powerful set of features, our software is your ultimate solution.
            Whether you're a small business owner, a freelancer, or part of a
            large corporation, our software is tailored to meet your unique
            needs. Don't miss out on the opportunity to take your work to the
            next level. Try our software today and experience the difference for
            yourself. Your success is just a click away! Feel free to adapt this
            paragraph to suit your specific software and target audience.
          </p>
          <p className="text-sm text-[]">
            Are you ready to revolutionize your workflow and supercharge your
            productivity? Look no further! Our cutting-edge software is designed
            to streamline your tasks, simplify your processes, and help you
            achieve your goals with ease. With a user-friendly interface and a
            powerful set of features, our software is your ultimate solution.
            Whether you're a small business owner, a freelancer, or part of a
            large corporation, our software is tailored to meet your unique
            needs. Don't miss out on the opportunity to take your work to the
            next level. Try our software today and experience the difference for
            yourself. Your success is just a click away! Feel free to adapt this
            paragraph to suit your specific software and target audience.
          </p>
        </div>
        <div className="md:w-[400px] md:ml-20 sm:w-[200px] flex justify-center items-center">
          <img src="img/products/cursor4.jpg" alt="" />
        </div>
      </div>
    </Container>
  );
};

export default SoftwareDevp;
