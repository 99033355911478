import React from 'react';
import AboutCom from '../components/AboutCom'


const About = () => {
    return (
        
           <AboutCom/>
       
    );
}

export default About;
