import React from 'react';
import Middle from '../components/Middle';
import Clints from '../components/Clints';
import ClintService from '../components/ClintService';
import Carousel from '../components/Carousel';





const Home = () => {
    return (
        <>
        <Carousel/>
        <Middle/>
        <Clints fluid={true}/>
        <ClintService/>
        </>
    );
}

export default Home;
