import React from 'react';
import Header from '../components/Header';
import Footers from '../components/Footers';
import { Outlet } from 'react-router-dom';



const Main = () => {
    return (
        <>
        <Header />
        <Outlet />
        <Footers />
    </>
    );
}

export default Main;
