import React from 'react';
import Container from '../../components/Container';


const WebsiteDevp = () => {
    return (
        <Container extraclas={'px-6  md:px-0 mb-8'}>
            <div className='grid  text-justify md:grid-cols-2 mt-10'>
                <div className='md:w-[400px] sm:w-[200px] flex justify-center items-center' ><img src="img/assets/1.jpg" alt="" /></div>
                <div className='flex px-2 flex-col gap-3'>
                    <p className='text-lg text-[#000]'>digisky-website Development</p>
                    <hr />
                    <p className='text-sm text-[#777]'>A business’s online presence, regardless of industry, can have a massive impact on its success. In this day and age, some businesses still don’t realize that a majority of their customers will visit their website before making a purchase.

Having a strong online presence, particularly a website, can be make or break for generating more revenue. Yes, the quality of your website impacts results, but the purpose of this article to stress the importance of making sure you have a website.</p>
                    <p className='text-sm text-[]'>I've helped numerous companies of varying sizes create their digital presences. In some cases, organizations are hesitant to get online because they feel they are not tech-savvy enough and don't understand how to manage a website. Other times, companies are concerned about the price.</p>
                </div>
            </div>
            <div className='grid  text-justify md:grid-cols-2 mt-10'>
                <div className='flex px-2 flex-col gap-3'>
                    <p className='text-lg text-[#000]'>digisky-website Development</p>
                    <hr />
                    <p className='text-sm text-[#777]'>One of the main reasons you should have a website for your business is to increase your organization's credibility. Chances are there are several providers offering a similar service to yours. One way you can stand out is by having a website that looks good and clearly communicates quality information to your consumers.

Without a website, people may question your legitimacy as a business. Having a website is an opportunity to make a great first impression and give people comfort that you’re a real business.</p>
                    <p className='text-sm text-[]'>Showcasing your brand to your prospective customers is one of the most important things that you can do. By clearly establishing who you are, what you represent and what you stand for, you increase the chances of your customers buying from you.

This is also something that can set you apart from your competitors. Without a website, it can be incredibly challenging to do this because people can't easily find quality and reliable information on your business.</p>
                </div>
                <div className='md:w-[400px] md:ml-20 sm:w-[200px] flex justify-center items-center' ><img src="img/assets/4.jpg" alt="" /></div>
            </div>

        </Container>
    );
}

export default WebsiteDevp;
