import React from 'react';
import Clints from '../components/Clints';
import ClintService from '../components/ClintService';
import Carousel from '../components/Carousel';


const OurClints = () => {
    return (
        <div>
             
            <Clints/>
            <ClintService/>
        </div>
    );
}

export default OurClints;
