import React,{createContext} from 'react';
const MainContext = createContext()

const Context = (props) => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Adds a smooth scrolling animation
        });
      }
      const MenuItems = [
        {
            name: "Home",
            link: "/"
        }, {
            name: "Services",
            link: "/services"
        }
        ,
        {
            name: "Our Clients",
            link: "/OurClient"
        },
        {
            name: "About",
            link: "/about"
        },
        {
            name: "Contact",
            link: "/contact"
        }
    ]
    return (
        <MainContext.Provider value={{ scrollToTop,MenuItems}}>
        {props.children}
    </MainContext.Provider>
    );
}

export default Context;
export { MainContext }
