import React from 'react';
import Middle from '../components/Middle'
import Carousel from '../components/Carousel';


const Service = () => {
    return (
       <>
       <Middle/>
       </>
    );
}

export default Service;
