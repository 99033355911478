import React from "react";
import Container from "../../components/Container";

const VoiceCall = () => {
  return (
    <Container extraclas={"px-6  md:px-0 mb-8"}>
      <div className="grid  text-justify md:grid-cols-2 mt-10">
        <div className="md:w-[400px] sm:w-[200px] flex justify-center items-center">
          <img src="img/assets/1.jpg" alt="" />
        </div>
        <div className="flex px-2 flex-col gap-3">
          <p className="text-lg text-[#000]">digisky-IVR software</p>
          <hr />
          <p className="text-sm text-[#777]">
            IVR software good for, if it can't help customers resolve their
            calls without needing to ask for an agent? In this strategy guide,
            learn how to leverage next-gen IVR telephony technology to make your
            call automation software work for customers, not against them, so
            they do not become frustrated and ask for a live agent (especially
            during times of high volume customer calls).{" "}
          </p>
          <p className="text-sm text-[]">
            {" "}
            An IVR system (IVRS) accepts a combination of voice telephone input
            and touch-tone keypad selection and provides the appropriate
            responses in the form of voice, fax, callback, email and other
            contact methods. In this article, learn more about the definition of
            ivr, and how to modernize your ivr application to improve call
            center efficiency and customer satisfaction.
          </p>
        </div>
      </div>
      <div className="grid  text-justify md:grid-cols-2 mt-10">
        <div className="flex px-2 flex-col gap-3">
          <p className="text-lg text-[#000]">digisky-IVR software</p>
          <hr />
          <p className="text-sm text-[#777]">
            Interactive Voice Response (IVR) is an automated phone system
            technology that allows incoming callers to access information via a
            voice response system of pre recorded messages without having to
            speak to an agent, as well as to utilize menu options via touch tone
            keypad selection or speech recognition to have their call routed to
            specific departments or specialists.
          </p>
          <p className="text-sm text-[]">
            A well designed IVR software system can help increase customer
            satisfaction and improve contact center operations and KPIs.
            Particularly during times of high call volume, an effective
            interactive voice response system can help avoid hold time by
            helping customers find answers and perform simple tasks themselves.
            In cases where a customer needs or requests to speak with a person,
            ivr technology can help route calls quickly and seamlessly to the
            best call center agent to address their inquiry.
          </p>
        </div>
        <div className="md:w-[400px] md:ml-20 sm:w-[200px] flex justify-center items-center">
          <img src="img/assets/4.jpg" alt="" />
        </div>
      </div>
    </Container>
  );
};

export default VoiceCall;
