import React from 'react';
import Container from '../../components/Container';


const VistingCard = () => {
    return (
        <Container extraclas={'px-6  md:px-0 mb-8'}>
        <div className='grid  text-justify md:grid-cols-2 mt-10'>
            <div className='md:w-[400px] sm:w-[200px] flex justify-center items-center' ><img  src="img/products/banner.jpg" alt="" /></div>
            <div className='flex px-2 flex-col gap-3'>
                <p className='text-lg text-[#000]'>Digisky – digital business card</p>
                <hr />
                <p className='text-sm text-[#777]'>A digital business card (also known as a virtual business card, electronic business card, smart business card, and digital visiting card) replaces the traditional business card and is a way to safely and securely share contact information. A digital business card contains all the information on a paper card but does much more. You can embed your picture, make multiple versions with different contact information, and customize it with different calls to action, effectively using the card as a sales, marketing, and customer service tool. Digital business cards often come in the format of mobile and web applications.</p>
                <p className='text-sm text-[]'>As we head into a possible recession, making informed decisions is crucial for buyers. Business cards are a necessary investment for anyone looking to grow their business or brand. Most business card apps offer a free version and subscription option (for people who want more customization and advanced features). By going digital, you can eliminate your business card budget or at least get even more bang for your buck.</p>
            </div>
        </div>
        <div className='grid  text-justify md:grid-cols-2 mt-10'>
            <div className='flex px-2 flex-col gap-3'>
                <p className='text-lg text-[#000]'>Digisky – digital business card </p>
                <hr />
                <p className='text-sm text-[#777]'>A digital business card (also known as a virtual business card, electronic business card, smart business card, and digital visiting card) replaces the traditional business card and is a way to safely and securely share contact information. A digital business card contains all the information on a paper card but does much more. You can embed your picture, make multiple versions with different contact information, and customize it with different calls to action, effectively using the card as a sales, marketing, and customer service tool. Digital business cards often come in the format of mobile and web applications.</p>
                <p className='text-sm text-[]'>As we head into a possible recession, making informed decisions is crucial for buyers. Business cards are a necessary investment for anyone looking to grow their business or brand. Most business card apps offer a free version and subscription option (for people who want more customization and advanced features). By going digital, you can eliminate your business card budget or at least get even more bang for your buck</p>
            </div>
            <div className='md:w-[400px] md:ml-20 sm:w-[200px] flex justify-center items-center' ><img src="img/products/banner.jpg" alt="" /></div>
        </div>

    </Container>
    );
}

export default VistingCard;
