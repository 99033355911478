import React from 'react';

const Container = (props) => {
    return (
        <div className={`${props.fluid == true ? 'w-full' : 'max-w-[1000px]'} mx-auto   ${props.extraclas ?? ''}`}>
            {props.children}
        </div>
    );
}

export default Container;
