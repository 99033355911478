import React from 'react';
import Container from '../../components/Container';

const AppDevp = () => {
    return (
        <Container extraclas={'px-6  md:px-0 mb-8'}>
            <div className='grid  text-justify md:grid-cols-2 mt-10'>
                <div className='md:w-[400px] sm:w-[200px] flex justify-center items-center' ><img src="img/banner3.jpg" alt="" /></div>
                <div className='flex px-2 flex-col gap-3'>
    <p className='text-lg text-[#000]'>Your Expert Partner in App Development</p>
    <hr />
    <p className='text-sm text-[#777]'>We are a leading provider of professional website development and software development services. With a proven track record in delivering cutting-edge solutions, we have been a trusted choice for over [X] years, helping businesses and organizations achieve their digital goals. Our team of experienced developers specializes in creating responsive websites, robust web applications, and innovative software solutions.</p>
    <p className='text-sm text-[]'>We work closely with clients from various industries, including e-commerce, healthcare, finance, and more, to build customized solutions that drive success. Our talented team of developers and designers combines creativity with technical expertise to deliver outstanding digital products. Our services include:</p>
</div>

            </div>
            <div className='grid  text-justify md:grid-cols-2 mt-10'>
            <div className='flex px-2 flex-col gap-3'>
            <p className='text-lg text-[#000]'>Your Expert Partner in App Development</p>
    <hr />
    <p className='text-sm text-[#777]'>We are a leading provider of professional website development and software development services. With a proven track record in delivering cutting-edge solutions, we have been a trusted choice for over [X] years, helping businesses and organizations achieve their digital goals. Our team of experienced developers specializes in creating responsive websites, robust web applications, and innovative software solutions.</p>
    <p className='text-sm text-[]'>We work closely with clients from various industries, including e-commerce, healthcare, finance, and more, to build customized solutions that drive success. Our talented team of developers and designers combines creativity with technical expertise to deliver outstanding digital products. Our services include:</p>
</div>
                <div className='md:w-[400px] md:ml-20 sm:w-[200px] flex justify-center items-center' ><img src="img/banner2.jpg" alt="" /></div>
            </div>

        </Container>
    );
}

export default AppDevp;
