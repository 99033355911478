import React from 'react';
import Container from '../../components/Container';


const StudioSetup = () => {
    return (
        <Container extraclas={'px-6  md:px-0 mb-8'}>
        <div className='grid  text-justify md:grid-cols-2 mt-10'>
            <div className='md:w-[400px] sm:w-[200px] flex justify-center items-center' ><img src="img/assets/1.jpg" alt="" /></div>
            <div className='flex px-2 flex-col gap-3'>
                <p className='text-lg text-[#000]'>digisky- online studio setup</p>
                <hr />
                <p className='text-sm text-[#777]'>Online classes have revolutionized the way teachers engage with students and facilitate learning. One key advantage is the increased flexibility in instructional approaches, allowing educators to tailor their teaching methods to suit individual student needs and learning styles. Additionally, online platforms offer a wealth of resources and tools that empower teachers to create interactive and multimedia-rich lessons, enhancing student engagement and comprehension.</p>
                <p className='text-sm text-[]'>In order to properly harness the benefits of online classes,and depart knowledge efficiently, a teacher must have a good setup at home. However, the benefits of a good setup is not immediately realized to new teachers who are just starting their online classes for students. So to assist in their decision about setting up their own studio, here are a few reasons & for setting up your own streaming studio. </p>
            </div>
        </div>
        <div className='grid  text-justify md:grid-cols-2 mt-10'>
            <div className='flex px-2 flex-col gap-3'>
                <p className='text-lg text-[#000]'>digisky- online studio setup</p>
                <hr />
                <p className='text-sm text-[#777]'>While online classes have their own benefits, they do have few disadvantages as well. One of the disadvantages is that there is no distinction between work hours and leisure hours as in a 9 to 5 job. Several professionals (Youtubers, Content Creators, Social Media Influencers) simply create a dedicated streaming studio in their house to separate their work and leisure hours. An idea which should be followed by every online class instructor.</p>
                <p className='text-sm text-[]'>Streaming studio also lets educators set up their online class equipment for a more professionally produced video. Moreover, they just have to set up their equipment – camera, lighting, mic, and others- only once, and the same setup can be used for future videos.</p>
            </div>
            <div className='md:w-[400px] md:ml-20 sm:w-[200px] flex justify-center items-center' ><img src="img/assets/4.jpg" alt="" /></div>
        </div>

    </Container>
    );
}

export default StudioSetup;
