import { useState } from 'react';
import Container from './Container';
import { AiFillCaretDown } from "react-icons/ai"
import { Link } from 'react-router-dom';


const Seller = () => {
    const [Toggle, SetToggle] = useState(false);


    const categries = ['ChatBot', 'App Development', 'Website Development', 'Digital Visting Card','Software Development']

    const products = [
        {
            img: "img/products/digitalcard.jpg",
            name: "Digital Visting & Nfc Card",
            link: "/visting_card"
        },
        {
            img: "img/products/chatbot.jpg",
            name: "WhatsApp Official Api",
            link: "/whatsapp_message"
        },
        {
            img: "img/products/IMG-20230906-WA0020.jpg",
            name: "Software Development",
            link: "/software_development"
        },
        {
            img: "img/products/studio setup.jpg",
            name: "Online Studio Setup",
            link: "/studio_setup"
        },
        {
            img: "img/products/greentik.jpg",
            name: "WhatsApp Green Tik Verification",
            link: "/appdev"
        },
        {
            img: "img/products/text msg.jpg",
            name: "Text  messaging  Software",
            link: "/text_message"
        }, 
        {
            img: "img/products/IMG-20230906-WA0027.jpg",
            name: "App & Website Development",
            link: "/website_develpment"
        },{
            img: "img/products/ivercall.jpg",
            name: "Voice Call & IVR",
            link: "/voice_call"
        }
        ,
        {
            img: "img/products/digital.jpg",
            name: "Social Media Marketing",
            link: "/social_media_marketing"
        }, 
      
    ];

    return (
        <Container>
  <div className='bg-[white]'>
            <div className='md:text-[30px] text-[18px] m my-5 text-[#22262A] text-center'>BEST SELLER</div>
            <div className='text-2xl w-[300px] mx-auto md:hidden p-2  shadow border flex justify-center'><AiFillCaretDown onClick={() => { SetToggle(!Toggle) }} /></div>
            <ul className='md:flex hidden justify-center   gap-10 mt-4 mb-4 cursor-pointer'>
                {
                    categries.map((d, i) => {
                        return <li className='hover:underline  hover:text-[#33A0FF]'>{d}</li>
                    })
                }
            </ul>
            <ul className={`md:hidden  justify-center gap-10 mt-4 mb-4 cursor-pointer 
            ${Toggle ? 'h-[350px] opacity-2' : 'h-0 opacity-0'}`}>
                {
                    categries.map((d, i) => {
                        return <li className='p-1 border text-center  hover:text-[#33A0FF]'>{d}</li>
                    })
                }
            </ul>
            <div className='grid grid-cols-2 md:grid-cols-3 px-3 md:px-0 gap-4  md:gap-8 justify-center mb-5'>
                {products.map((data, i) => {
                    return <Box {...data} key={i} />
                })}
            </div>
        </div>
        </Container>
      
    );
}

export default Seller;

const Box = (props) => {
    
    return (
        <div  className='border p-4 overflow-hidden hover:shadow-lg'>
                <div className='max-w-sm '><img className='h-[100%] transition duration-300 ease-in-out hover:scale-110 w-[100%]' src={props.img} alt="" /></div>
                <div className='text-center'>
                    <h1 className='text-[#5b5b5f] capitalize text-[12px] md:text-[16px]  font-semibold my-4'>{props.name}</h1>
                    <div  class="bg-transparent text-[12px] md:text-[16px]  hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 my-2 md:px-3 border border-blue-500 hover:border-transparent rounded">
                    <Link to={props.link}>View Full Details</Link>
                    </div>
                  
                </div>
            </div>
    );
};




