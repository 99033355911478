import React, { useState,useContext } from 'react';
import Container from './Container';
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai"
import { BsWhatsapp } from "react-icons/bs"
import { Link } from 'react-router-dom';
import { MainContext } from '../context/Context';


const Header = () => {
    const [toggle, settoggle] = useState(false)
    const MenuItems = [
        {
            name: "Home",
            link: "/",
        }, {
            name: "Services",
            link: "/services"
        }
        ,
        {
            name: "Our Clients",
            link: "/OurClient"
        },
        {
            name: "About",
            link: "/about"
        },
        {
            name: "Contact",
            link: "/contact"
        }
    ]
   

    const closeMenu = () => {
        settoggle(false);
    }

    return (
        <Container fluid={true}>
            <nav style={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}} className="bg-white ">
                <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                    <div className="flex items-center">
                        <div  className='rounded-full overflow-hidden w-[70px] h-[70px] '>
                        <img src="img/clints/logo.jpg" alt='' className="h-[70px] h-[70px] overflow-hidden mr-3"/>
                        </div>
                      
                    </div>
                    <AiOutlineMenu onClick={() => { settoggle(true) }} className='md:hidden  text-navcolor  text-[30px]' />
                    <div className="hidden w-full md:block md:w-auto" id="navbar-default">
                        <ul className="font-medium items-center justify-between  flex flex-col p-4 md:p-0 mt-4 rounded-lg md:flex-row md:space-x-[30px] lg:space-x-[70px] md:mt-0 md:border- text-navcolor">
                            {
                                MenuItems.map((page, index) => {
                                    return (
                                        <li key={index}>
                                            <Link to={page.link} onClick={closeMenu} className="block py-2 pl-3 pr-4 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 ">{page.name}</Link>
                                        </li>
                                    )
                                })
                            }
                            <li>
                                <div  className="text-navcolor  p-3 flex bg-white   focus:outline-none border focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm">
                                    <Link to='https://api.whatsapp.com/send/?phone=9929245508&text=Hello%2C+I+am+interested+in your services ' className="block md:hover:bg-transparent font-bold  md:border-0 hover:bg-[blue]  hover:text-[white] zoom-hover"><BsWhatsapp className='text-[18px] animate-bounce text-[green] mr-2 hover:text-[black] float-left'/>ChatNow</Link>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="w-full md:hidden md:w-auto" id="navbar-default">
                        <ul style={{ zIndex: '99999' }} className={`md:hidden fixed w-screen h-[100vh] bg-white text-navcolor  flex flex-col gap-10  items-center justify-center pt-6  duration-300 top-[0px]
                ${toggle == true ? 'left-[0%]' : 'left-[-100%]'}
                `}>
                            {
                                MenuItems.map((page, index) => {
                                    return (
                                        <li key={index}>
                                            <Link to={page.link} onClick={closeMenu} className="block py-2 pl-3 pr-4 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0  ">{page.name}</Link>
                                        </li>
                                    )
                                })
                            }
                            <li>
                                <AiOutlineClose className='text-[30px] text-navcolor ' onClick={() => { settoggle(false) }} />
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </Container>
    );
}

export default Header;
