import React from 'react';
import Container from '../components/Container';

const Services = () => {
    const videoMediaLinks = [
        {
          name: 'Facebook',
          url: 'https://www.youtube.com/embed/tIi39wiwAM8',
        },
        {
          name: 'WhatsApp',
          url: 'https://www.youtube.com/embed/Sg3x_xJHEfU',
        },
        {
          name: 'Instagram',
          url: 'https://www.youtube.com/embed/MQJpxsgkpD0',
        }
      ];
      
    return (
        <Container fluid={true}>
            <div>
            <h2 className=" text-center mt-5 text-3xl font-bold">Testimonials</h2>
            <hr className='w-[40%] mb-8 text-1xl mx-auto'/>
                <div className="grid grid-cols-1  md:grid-cols-3  py-10">
                    
                  {
videoMediaLinks.map((data,index)=>{
return   <div key={index} className="relative mb-10 w-[10px] mx-auto   flex justify-center  md:mb-0 md:aspect-none md:w-full md:h-full">
<iframe style={{border:'2px solid #f0f4f5'}} className='mb-10' src={data.url} title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
</div>
})
                  }
                </div>
            </div>
        </Container>
    );
}

export default Services;
