import React from "react";
import Container from "./Container";

const Clints = (props) => {
  const clint = [
    {
      img: "img/clints/1.jpg",
      name: "Commando Academy",
    },
  
    {
      img: "img/clints/3.jpg",
      name: "Nuevocare ",
    },
    {
      img: "img/clints/4.jpg",
      name: "af absolute fit",
    },
    {
      img: "img/clints/5.jpg",
      name: "KnowledgeBox",
    },
    {
      img: "img/clints/6.jpg",
      name: "Samyak",

    },
    {
      img: "img/clints/7.jpg",
      name: "exam dost",
    },
    {
      img: "img/clints/8.jpg",
      name: "pathshala",
    },
    {
      img: "img/clints/9.jpg",
      name: "choki dhani",
    },
    {
      img: "img/clints/10.jpg",
      name: "v-grow",
    },
    {
      img: "img/clints/11.jpg",
      name: "dental hub",
    },
    {
      img: "img/clints/12.jpg",
      name: "rojgar ankit",
    } , {
        img: "img/clints/2.jpg",
        name: " Kwirky Furniture",

      },{
        img: "img/clints/14.jpg",
        name: "Exam Darbar",

      },{
        img: "img/clints/15.jpg",
        name: "Rankers Gurukul",

      },{
        img: "img/clints/16.jpg",
        name: "English Connection",

      },{
        img: "img/clints/19.jpg",
        name: "Beme",

      },{
        img: "img/clints/18.jpg",
        name: "yourstudy",

      },{
        img: "img/clints/17.jpg",
        name: "Lakshya Classes",

      }
  ];
  return (
    <Container fluid={true} extraclas=" bg-blue-500">
      <section
        className={`py-8 bg-[#f0f4f5] ${props.fluid == true ? "mt-8" : ""}`}
      >
        <div className="flex mb-2 justify-center">
          <button className="animate-bounce inline-block py-4 px-8 bg-teal-500 text-teal-100 rounded-lg">
            Meet our clients
          </button>
        </div>
        <hr className="text-[red]" />
        <div className="grid px-4 grid-cols-2 md:grid-cols-6 ">
          {clint.map((data, index) => {
            return (
              <div
                key={index}
                className=" mt-6 p-[20px] flex flex-col text-center justify-center "
              >
                <div>
                 
                    <img
                      src={data.img}
                      alt={data.name}
                      style={{borderRadius:"100%"}}
                    />
                
                  <h2 className="mt-2 capitalize  text-black">{data.name}</h2>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </Container>
  );
};

export default Clints;
