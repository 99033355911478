import React from 'react';

const About = () => {
  return (
    <div>
      <div className="mx-auto md:px-6">  
        <section className="md:p-0  p-5">
          <div className="container mx-auto text-center lg:text-left xl:px-32">
            <div className=" grid  items-center lg:grid-cols-2">
              <div className="mb-12  lg:mb-0">
                <div style={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}}
                  className="relative z-[1] block rounded-lg md:px-12 lg:-mr-14">
                  <h2 className="mb-8 text-3xl font-bold  capitalize">digisky (creative technology)</h2>
                  <p className="mb-8 pb-2 text-[#1e457b] font-bold lg:pb-0">
                  Digital Visting & Nfc Card,Whats App Bulk Sms,Software Development,Online Studio Setup,WhatsApp Green Tik Verification,Text Messagae Software,App & Website Development,Voice Call & IVR,Social Media Marketing
                  </p>

                  <div className="mx-auto text-[green] mb-8 flex flex-col md:flex-row md:justify-around lg:justify-between">
                    <p className="mx-auto mb-4 flex items-center md:mx-0 md:mb-2 lg:mb-0">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                        stroke="currentColor" className="mr-2 h-5 w-5">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      Best team
                    </p>

                    <p className="mx-auto mb-4 flex items-center md:mx-0 md:mb-2 lg:mb-0">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                        stroke="currentColor" className="mr-2 h-5 w-5">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      Best Support
                    </p>

                    <p className="mx-auto mb-2 flex items-center md:mx-0 lg:mb-0">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                        stroke="currentColor" className="mr-2 h-5 w-5">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      Best Services
                    </p>
                  </div>

                  <p className="mb-0 text-[black]">
                  We are provide best marketing tools and software in Best Price. DIGISKY Support & Development Team Very Creative and Responsible. Our mission is to take Technology where it's never been before
                  <p className='mt-20'>    Lets do it in a Simpler, Smarter and Faster way.</p>
              





                 <p className='mt-20'>Thanks For Join Digitalsky Have a Great Day</p> 
                  </p>
                </div>
              </div>

              <div className='md:ml-5 my-6'>
                <img src="img/about.jpg"
                  className="w-full md:h-[740px] rounded-lg shadow-lg dark:shadow-black/20" alt="" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default About;
