import React from 'react';
import ContactDetails from '../components/ContactDetails';



const Contact = () => {
    return (
       <>
       <ContactDetails/>
       </>
    );
}

export default Contact;
