import React from "react";
import Container from "../../components/Container";

const TextMsg = () => {
  return (
    <Container extraclas={"px-6  md:px-0 mb-8"}>
      <div className="grid  text-justify md:grid-cols-2 mt-10">
        <div className="md:w-[400px] sm:w-[200px] flex justify-center items-center">
          <img src="img/assets/1.jpg" alt="" />
        </div>
        <div className="flex px-2 flex-col gap-3">
          <p className="text-lg text-[#000]">
           digisky-text Bulk messaging software
          </p>
          <hr />
          <p className="text-sm text-[#777]">
          Bulk messaging is a perfect platform to be used for organising entertaining mobile marketing campaigns that can not only help you raise additional revenue but also make people remember your brand and stick to it.
          </p>
          <p className="text-sm text-[]">
            Bulk SMS delivery helps businesses build relationships that drive
            scalable results. This purpose-built service is especially useful in
            assisting first-time entrepreneurs, and SMEs succeed in reaching the
            right prospects during the complex purchase process. In the same
            time, it empowers businesses to create endless opportunities through
            strategic well-targeted exposure. Bulk SMS enables its users to send
            mass text messages nationally and internationally. This is the
            reason why this type of SMS delivery is one of the best solutions
            for businesses aiming to reach a specific audience, locally or
            globally.
          </p>
        </div>
      </div>
      <div className="grid  text-justify md:grid-cols-2 mt-10">
        <div className="flex px-2 flex-col gap-3">
          <p className="text-lg text-[#000]">
          digisky-text Bulk messaging software
          </p>
          <hr />
          <p className="text-sm text-[#777]">
            You should think of mass messaging as your additional business offer
            that can increase sales and revenue because it empowers you to reach
            customers when it’s most suitable for them, with the most critical
            information or service they need from you. You can lead them through
            the sales funnel to reach macro (purchase) conversions or entice
            small, micro engagements, depending on the type of business you’re
            in and your business goals.
          </p>
          <p className="text-sm text-[]">
            Bulk SMS delivery helps businesses build relationships that drive
            scalable results. This purpose-built service is especially useful in
            assisting first-time entrepreneurs, and SMEs succeed in reaching the
            right prospects during the complex purchase process. In the same
            time, it empowers businesses to create endless opportunities through
            strategic well-targeted exposure. Bulk SMS enables its users to send
            mass text messages nationally and internationally. This is the
            reason why this type of SMS delivery is one of the best solutions
            for businesses aiming to reach a specific audience, locally or
            globally.
          </p>
        </div>
        <div className="md:w-[400px] md:ml-20 sm:w-[200px] flex justify-center items-center">
          <img src="img/assets/4.jpg" alt="" />
        </div>
      </div>
    </Container>
  );
};

export default TextMsg;
